body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f7f9ff; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-body {
  font-family: Poppins;
  font-size: 14px;
}

.react-confirm-alert .react-confirm-alert-body {
  font-family: Poppins;
  font-size: 14px;
}

.react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group
  button {
  font-family: Poppins;
  font-size: 12px;
  color: white;
  background-color: #ff6a61;
}
